.ekranResmi2022051101231Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
  object-fit: cover;
}
.ger {
  position: absolute;
  top: 39.1px;
  left: 54.4px;
  transform: rotate(-26.05deg);
  transform-origin: 0 0;
}
.ekranResmi2022051101231Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
}
.beyaz6gen111 {
  position: absolute;
  top: calc(50% - 184px);
  left: calc(50% - 190.45px);
  width: 378px;
  height: 423px;
  object-fit: cover;
}
.blockchain {
  position: relative;
}
.blockchainWrapper {
  top: 229.4px;
  left: 0;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.bistWrapper,
.blockchainWrapper,
.nftWrapper {
  position: absolute;
  border-radius: var(--br-81xl);
  background-color: black;
  width: 256.4px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
}
.bistWrapper {
  top: 48px;
  left: 470.2px;
  transform: rotate(45deg);
  transform-origin: 0 0;
}
.nftWrapper {
  top: 0;
  left: 196.3px;
}
.beyaz6gen111Parent {
  position: absolute;
  top: calc(50% - 239px);
  left: calc(50% - 326px);
  width: 651.5px;
  height: 478px;
}
.krcsideInvest {
  position: relative;
  background-color: black;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: white;
  font-family: var(--font-space-grotesk);
}
