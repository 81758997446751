.frameChild,
.sadaa11 {
  position: absolute;
  top: -2.3px;
  left: 159.2px;
  width: 98.8px;
  height: 169.4px;
}
.sadaa11 {
  top: 44px;
  left: 204px;
  width: 100.2px;
  height: 106px;
  object-fit: cover;
}
.frameItem {
  position: absolute;
  top: 183.9px;
  left: 40px;
  width: 56.5px;
  height: 138.7px;
}
.pngtreeDragonVectorIconIll {
  position: absolute;
  top: 78px;
  left: 0;
  width: 102px;
  height: 126px;
  object-fit: cover;
}
.beyaz6gen111,
.frameInner {
  position: absolute;
  top: 295.6px;
  left: 212.5px;
  width: 61.2px;
  height: 66.7px;
}
.beyaz6gen111 {
  top: 356px;
  left: 172px;
  width: 81px;
  height: 86px;
  object-fit: cover;
}
.ekranResmi2022051101231Icon {
  position: relative;
  width: 152px;
  height: 159px;
  object-fit: cover;
}
.canKirac,
.krckrccorpnet {
  position: relative;
}
.krckrccorpnet {
  margin-top: -3px;
}
.canKiracParent,
.ekranResmi2022051101231Parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.canKiracParent {
  width: 183px;
  height: 67px;
  justify-content: center;
  margin-top: -20px;
}
.ekranResmi2022051101231Parent {
  position: absolute;
  top: 150px;
  left: 59px;
  width: 212px;
  height: 238px;
  justify-content: flex-start;
}
.vectorParent {
  position: absolute;
  top: calc(50% - 233px);
  left: calc(50% - 160px);
  width: 320px;
  height: 467px;
}
.krcsideMainMobil {
  position: relative;
  background-color: black;
  width: 100%;
  height: 1068px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: white;
  font-family: var(--font-kelly-slab);
}
