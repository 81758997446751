.ekranResmi2022051101231Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
  object-fit: cover;
}
.ger {
  position: absolute;
  top: 39.1px;
  left: 54.4px;
  transform: rotate(-26.05deg);
  transform-origin: 0 0;
}
.ekranResmi2022051101231Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
}
.dgifmaker746321Icon,
.sadaa12 {
  position: absolute;
  top: -408px;
  left: 308px;
  width: 250px;
  height: 250px;
  object-fit: cover;
}
.sadaa12 {
  top: -328.6px;
  left: 84px;
  width: 593.1px;
  height: 611.8px;
}
.mobilUygulama {
  position: relative;
}
.mobilUygulamaWrapper {
  position: absolute;
  top: 181px;
  left: 246.6px;
  border-radius: var(--br-81xl);
  background-color: black;
  width: 256.4px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
  transform: rotate(45.61deg);
  transform-origin: 0 0;
}
.dgifmaker746321Parent {
  position: absolute;
  top: 795px;
  left: 229px;
  width: 556.3px;
  height: 521.6px;
  transform: rotate(-46.58deg);
  transform-origin: 0 0;
}
.sadaa121 {
  position: absolute;
  top: 32.5px;
  left: -471.5px;
  width: 598.6px;
  height: 615.4px;
  object-fit: cover;
}
.sadaa12Parent,
.servislerWrapper {
  position: absolute;
  transform-origin: 0 0;
}
.servislerWrapper {
  top: 371.1px;
  left: 214.4px;
  border-radius: var(--br-81xl);
  background-color: black;
  width: 183px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
  transform: rotate(-59.65deg);
}
.sadaa12Parent {
  top: 40px;
  left: 1120.7px;
  width: 556.3px;
  height: 521.6px;
  transform: rotate(73.69deg);
}
.sadaa122 {
  position: absolute;
  top: -318px;
  left: 81.3px;
  width: 599.7px;
  height: 616.1px;
  object-fit: cover;
}
.aplerWrapper,
.sadaa12Group {
  position: absolute;
  transform-origin: 0 0;
}
.aplerWrapper {
  top: 244.5px;
  left: 234.2px;
  border-radius: var(--br-81xl);
  background-color: black;
  width: 183px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
  transform: rotate(29.94deg);
}
.sadaa12Group {
  top: 391px;
  left: 0;
  width: 556.3px;
  height: 521.6px;
  transform: rotate(-44.66deg);
}
.frameParent {
  position: absolute;
  top: calc(50% - 541px);
  left: calc(50% - 638px);
  width: 1277px;
  height: 1081px;
}
.krcsideDigital {
  position: relative;
  background-color: black;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: white;
  font-family: var(--font-space-grotesk);
}
