.ekranResmi2022051101231Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
  object-fit: cover;
}
.ger {
  position: absolute;
  top: 39.1px;
  left: 54.4px;
  transform: rotate(-26.05deg);
  transform-origin: 0 0;
}
.ekranResmi2022051101231Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 213px;
  height: 193px;
}
.pngtreeDragonVectorIconIll {
  position: absolute;
  top: calc(50% - 246px);
  left: calc(50% - 312.25px);
  width: 644px;
  height: 506px;
  object-fit: cover;
}
.krcEGun {
  position: relative;
}
.krcEGunWrapper {
  position: absolute;
  top: calc(50% + 297.6px);
  left: calc(50% - 689.25px);
  border-radius: var(--br-81xl);
  background-color: black;
  width: 927.1px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.krcBaphomet {
  position: relative;
  transform: rotate(-178.89deg);
  transform-origin: 0 0;
}
.krcBaphometWrapper,
.murphyControllerWrapper {
  position: absolute;
  top: calc(50% + 331.5px);
  left: calc(50% + 655.35px);
  border-radius: var(--br-81xl);
  background-color: black;
  width: 927.1px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-66xl) var(--padding-13xl);
  box-sizing: border-box;
  transform: rotate(-135deg);
  transform-origin: 0 0;
}
.murphyControllerWrapper {
  top: calc(50% + 310px);
  left: calc(50% - 362.25px);
  width: 723.1px;
  transform: rotate(-0.47deg);
}
.pngtreeDragonVectorIconIllParent {
  position: absolute;
  top: calc(50% - 358px);
  left: calc(50% - 689px);
  width: 1378.5px;
  height: 716px;
}
.krcsideEndus {
  position: relative;
  background-color: black;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: white;
  font-family: var(--font-space-grotesk);
}
