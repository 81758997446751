.frameChild,
.sadaa11 {
  position: absolute;
  top: -1.2px;
  left: 461.2px;
  width: 429.6px;
  height: 242.6px;
}
.sadaa11 {
  top: 72.6px;
  left: 757.6px;
  width: 264.2px;
  height: 230.3px;
  object-fit: cover;
}
.frameItem {
  position: absolute;
  top: 310.8px;
  left: 105.5px;
  width: 219.3px;
  height: 253.4px;
}
.pngtreeDragonVectorIconIll {
  position: absolute;
  top: 113px;
  left: 0;
  width: 277px;
  height: 257px;
  object-fit: cover;
}
.beyaz6gen111,
.frameInner {
  position: absolute;
  top: 461.9px;
  left: 606px;
  width: 221.4px;
  height: 95.7px;
}
.beyaz6gen111 {
  top: 557px;
  left: 702px;
  width: 205px;
  height: 221px;
  object-fit: cover;
}
.cankirac {
  position: absolute;
  top: 556.3px;
  left: 156.5px;
  transform: rotate(15.85deg);
  transform-origin: 0 0;
}
.ekranResmi2022051101231Icon {
  position: relative;
  width: 323px;
  height: 319px;
  object-fit: cover;
}
.canKirac,
.krckrccorpnet {
  position: relative;
}
.krckrccorpnet {
  margin-top: -10px;
}
.canKiracParent,
.ekranResmi2022051101231Parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.canKiracParent {
  width: 510px;
  height: 181px;
  justify-content: center;
}
.ekranResmi2022051101231Parent {
  position: absolute;
  top: 221px;
  left: 212px;
  justify-content: flex-start;
  font-size: 36px;
  font-family: var(--font-kelly-slab);
}
.vectorParent {
  position: absolute;
  top: calc(50% - 389px);
  left: calc(50% - 511px);
  width: 1021.8px;
  height: 778px;
}
.krcsideMain {
  position: relative;
  background-color: black;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: 2px;
  color: white;
  font-family: var(--font-inter);
}
